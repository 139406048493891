import {useEffect, useState} from 'react';
import { useGetClientReportByDateQuery } from '../redux/apiSlice';
// import useDeepCompareEffect from 'use-deep-compare-effect';

const useSetVisitGoals = ({clientId, hasGoals, searchStart, searchEnd}) => {
  // console.log("hook props: ", clientId, hasGoals, searchStart, searchEnd)

    const [skip, setSkip] = useState(true)
    const [visitGoals, setVisitGoals] = useState([])

    const { data: plan } = useGetClientReportByDateQuery({clientId, searchStart, searchEnd}, {skip});

    useEffect(() => {
      if(clientId) {
        setSkip(false)
      }
    }, [clientId])

    // console.log("setGoals plan: ", plan)
    const goals = plan?.goals
  
  // console.log("plan goals: ", goals, "visit goals: ", hasGoals)

  useEffect(() => {
    const planGoals = goals?.map(goal => (
      {
        title: goal.title,
        marked: false,
        note: ''
      }
    ))
      // console.log("goals from plan: ", planGoals)
    
      if (hasGoals?.length >= 1) {
        const result = planGoals?.map(i => {
          const new_info = hasGoals.find(g => g.title === i.title);
          return {
            ...i,
            ...new_info
          };
        })
        // console.log("event has goals: ", result)
        
        setVisitGoals(result)
      } else {    
    
      setVisitGoals(planGoals)
      }
  
  
  
  
  }, [goals, hasGoals]);
  

// console.log("visitGoals: ", visitGoals)
  return visitGoals
}

export default useSetVisitGoals