import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useGetScheduleByDateQuery } from '../../redux/apiSlice';
import ScheduleVisit from '../visitviews/InScheduleVisit';
import ViewVisit from '../visitviews/ViewVisit';
import { setOpenState, setScheduleOpenState } from '../../redux/slices/visitSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Grid, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import './Schedule.css';

export default function Schedule ({userId}) {

    const dispatch = useDispatch()

    const isOpen = useSelector(state => state.visit.openModal)
    const schedulerOpen = useSelector(state => state.visit.openScheduler)

    function getFirstOfMonth() {
        const now = new Date();
        const firstDate = new Date(now.getFullYear(), now.getMonth(), 1); 
        return firstDate.toISOString(); 
      }

    const [currentRange, setCurrentRange] = useState({
        start: getFirstOfMonth(),
        end: new Date().toISOString(),
      });
    

    const [visitId, setVisitId] = useState()
    const [eventDetails, setEventDetails] = useState()
    const [hasDate, setHasDate] = useState()

    

    const { data: visits = [], refetch} = useGetScheduleByDateQuery({userId, isStart: currentRange.start, isEnd: currentRange.end}, { skip: !currentRange.start })

    useEffect(() => {
        if (userId) {
            refetch()
        }
    }, [userId, refetch])

    const handleDateChange = (dateInfo) => {
        const { start, end } = dateInfo;
        setCurrentRange({ start: start.toISOString(), end: end.toISOString() });
      };

    //   console.log("current range: ", currentRange.start, currentRange.end)
    
      useEffect(() => {
        // Refetch events when currentRange changes
        if (currentRange.start && currentRange.end) {
            // console.log("range changed")
          refetch();
        }
      }, [currentRange, refetch]);

    const handleDateClick = (arg) => {
        // console.log("1: ", arg.dateStr)

        // eslint-disable-next-line
        let dateNoTime = new Date(arg.dateStr.replace(/-/g, '\/'))
        // console.log("2: ", dateNoTime)

        let dateTime = new Date();
        dateNoTime.setHours(dateTime.getHours());
        dateNoTime.setMinutes(dateTime.getMinutes());
        dateNoTime.setSeconds(0);

        // console.log("3: ", dateNoTime)
        let adjust = new Date(dateNoTime).toISOString()
        // console.log("4: ", adjust)
        setHasDate(adjust)
        let payloadObj = {
            open: true,
            time: adjust
        }
        dispatch(setScheduleOpenState(payloadObj))
        
    }

    const handleEventClick = (arg) => {
    
        const visitId = arg.event.extendedProps.visitId
        // console.log("visitId: ", visitId, "event: ", arg.event.extendedProps)
        setVisitId(visitId)
        setEventDetails(arg.event.extendedProps) 
        dispatch(setOpenState(true))
    }
    // console.log("hasDate: ", hasDate)
        
    const singleEvent = 
            <div>
                <ViewVisit 
                    event={eventDetails} 
                    isOpen={isOpen}
                    visitId={visitId}
                />
            </div>

    const dateSchedule = 
            <div>
                <ScheduleVisit
                    isOpen={schedulerOpen}
                    hasDate={hasDate}
                />
            </div>
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    // console.log("mobile: ", isMobile, "tablet: ", isTablet)

    const [initial, setInitial] = useState('dayGridMonth');

    useEffect(() => {
        if (isMobile){
            setInitial("timeGridDay")
        } else if (isTablet) {
            setInitial("timeGridWeek")
        } else {
            setInitial("dayGridMonth")
        }
    }, [isMobile, isTablet])

    return (
        <>
        <Grid container spacing={2} sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Card>
                    <ScheduleVisit />
                </Card>
            </Grid>
            <Grid item xs={12} >
                <Box sx={{ overflowX: 'auto', width: '80%', height: '80%'}}>
                    <FullCalendar
                        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                        initialView={initial}
                        headerToolbar= {{
                            left: 'prev,next',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        // aspectRatio={.25}
                        // height={isMobile ? '100%': 'auto'}
                        editable={true}
                        events={visits}
                        datesSet={handleDateChange} // This is called on view change
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                    />
                    {singleEvent}
                    {dateSchedule}
                </Box>
            </Grid>            
        </Grid>
        </>
    )
}

Schedule.propTypes = {
    userId: PropTypes.any
}