import React, { useEffect, useState } from 'react';
import Bullet, {Tooltip} from 'devextreme-react/bullet';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { Card, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { useGetSnapshotMutation } from '../../redux/apiSlice';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './Snapshot.css'

 const Snapshot = ({userId}) => {
    // const { id } = useAuth()
	// const userId = id
    
    // console.log("userId: ", userId)
    
    const [isEnd, setIsEnd] = useState()
    const [isStart, setIsStart] = useState()
    const [header, setHeader] = useState()
    const [title, setTitle] = useState()
    const [output, setOutput] = useState()
    const [qtrs, setQtrs] = useState()
    const [compDates, setCompDates] = useState()

    const yearType = useSelector(state => state.settings?.yearType)

    const  [pullData, {data, refetch}]  = useGetSnapshotMutation({userId, isStart, isEnd})

    // console.log("snapshot data: ", data)

    useEffect(() => {
        if (userId) {
            // console.log("useEffect userId: ", userId)
            refetch()
        }
    }, [userId, refetch])

    useEffect(() => {
        if (yearType === 'calendarYear') {
            setQtrs([{qtr: 1, mths: [0,1,2]}, {qtr: 2, mths: [3,4,5]}, {qtr: 3, mths: [6,7,8]}, {qtr: 4, mths: [9,10,11]} ])
        } else if ( yearType === 'fiscalYear') {
            setQtrs([{qtr: 1, mths: [6,7,8]}, {qtr: 2, mths: [9,10,11]}, {qtr: 3, mths: [0,1,2]}, {qtr: 4, mths: [3,4,5]} ])
        } else {
            setQtrs([{qtr: 1, mths: [0,1,2]}, {qtr: 2, mths: [3,4,5]}, {qtr: 3, mths: [6,7,8]}, {qtr: 4, mths: [9,10,11]} ])
        }
    }, [yearType])
    

    useEffect(() => {
        if (qtrs) {
        const currentMth = new Date().getMonth()
        // console.log(currentMth)
    
        const currentQtr = qtrs?.filter(({mths}) => mths.includes(currentMth))
        // console.log(currentQtr)
        
        setCompDates(currentQtr[0].mths.map(i => new Date(new Date(new Date(new Date().setMonth(i)).setDate(1)).setHours(0,0,0,0)).toISOString()))

    
        const index = currentQtr[0].mths.findIndex(x => x === currentMth)
        // console.log(index)
    
        let end = new Date().toISOString();
        let start
    
        if (index === 0) {
            start = new Date(new Date(new Date(end).setDate(1)).setHours(0, 0, 0,)).toISOString()
        } else if (index === 1) {
            const startMth = currentMth - 1
            // console.log(currentMth, startMth)
            start = new Date(new Date(new Date(end).setMonth(startMth, 1)).setHours(0, 0, 0)).toISOString()
        } else if (index === 2) {
            const startMth = currentMth -2
            // console.log(currentMth, startMth)
            start = new Date(new Date(new Date(end).setMonth(startMth, 1)).setHours(0, 0, 0)).toISOString()
        }
        setIsStart(start)
        setIsEnd(end)

        setTitle(`Current Quarter: ${currentQtr[0].qtr}`)
        const newArr = currentQtr[0].mths.map(i => new Date(new Date().setMonth(i)).toLocaleString('default', { month: 'long' }))

        newArr.push("Total")
        // console.log(newArr)
        setHeader(newArr)

        
        }

    }, [qtrs])

    useEffect(() => {
        if(isStart && isEnd && userId) {
            // console.log("snapshot isStart: ", isStart)
            pullData({userId, isStart, isEnd})
        }
    }, [isStart, isEnd, userId])


    // console.log("start: ", isStart,"end: ", isEnd)

    // console.log("compdates: ", compDates)
    // console.log("snapshot data: ", data)

    useEffect(() => {
        if(data) {

            let dataArr = [...data]
        
            const contractsByMonth = (type, hours) => {
                if (type === "Quarterly") {
                let monthHours = hours / 3
                return +(Math.round(monthHours + "e+2")  + "e-2");
            } else return hours	  
            }

            const formatMonthInArray = (array) => {
                    return array.map(item => {
                      const [year, month] = item.date.split('-'); // Split the date into year and month
                      const formattedMonth = String(month).padStart(2, '0'); // Add leading zero if necessary
                      return { ...item, date: `${year}-${formattedMonth}` }; // Update the date field with the formatted month
                    });
                  }

            const hourAdjust = dataArr.map(i => {
                // console.log("i: ", i)

                if (i.hours == undefined) {
                    let monthly = contractsByMonth(i.contracts.type, i.contracts.hours)
                    const contracts = {...i.contracts, monthHours: monthly, total: i.contracts.hours} 
                    console.log("monthly: ", monthly, "contracts: ", contracts)

                    const hours = [];

                    const start = new Date(isStart)
                    const end = new Date(isEnd)

                    while (start <= end) {
                        // console.log("start 1: ", start)
                        const year = start.getFullYear();
                        const month = String(start.getMonth() + 1).padStart(2,'0'); //adds leading 0 if needed

                        hours.push({ date: `${year}-${month}` , count: 0, month: monthly}) //new Date(start).toISOString()
                        start.setMonth(start.getMonth() + 1, 1)
                        // console.log("start 2: ", start)
                        // console.log("in while hours: ", hours)
                    }
                    const sum = 0
                    const colors= ['#73E078', '#26A69A', '#26C6DA', '#29B6F6']

                    // console.log("hours no data: ", hours)
                    return {...i, contracts, hours, sum, colors}

                } else {
                    const monthAdj = formatMonthInArray(i.hours) 

                    let monthly = contractsByMonth(i.contracts.type, i.contracts.hours)
                    const contracts = {...i.contracts, monthHours: monthly, total: i.contracts.hours} 

                    let sum = i.hours.reduce(function (acc, curr){return acc + curr.count}, 0)
                    // console.log("sum: ", sum)
                    const colors= ['#73E078', '#26A69A', '#26C6DA', '#29B6F6']
                    
                    const hours = monthAdj.map(x => {return ( {date: x.date, count: x.count, month: monthly } )})
                    // console.log("hours with data: ", hours)
                    
                    return {...i, contracts, hours, sum, colors};
                }
                })
                // console.log("hoursadjust: ", hourAdjust)
            
            const dateCheck = hourAdjust.map(i => {
                    let newObj = {}

                    let hours = i.hours

                    //   console.log("hours: ", hours)

                    // console.log("dateCheck i: ", i)

                    // let itemsDate = i.hours.map(x => { let adjustMth = new Date(x.date).getMonth() + 1; return (new Date(new Date(new Date(new Date().setMonth(adjustMth)).setDate(1)).setHours(0,0,0,0)).toISOString())})
                    let itemsDate = i.hours.map(x => { return new Date(x.date).toISOString().slice(0, 10)})
                    // console.log("itemsDate", itemsDate)
                    
                    let getTotal = i.hours[0].total
                    // console.log("gettotal: ", getTotal)
                    
                    let newCompDates = compDates.map(x => { return new Date(x).toISOString().slice(0, 10)})
                    // console.log("compDates adjusted: ", newCompDates)
                    let result = newCompDates.filter(y => !itemsDate.includes(y))
                    // console.log("result:", result)
                    
                    if (result.length) {
                        result.forEach(d => {
                            newObj = {
                            count: 0,
                          date: d,
                          total: getTotal
                        };
                        hours.push(newObj)
                       } )
                    } else {
                        console.log(false)
                    }
                    
                    hours.sort(function(a,b){
                      return new Date(a.date) - new Date(b.date);
                    });
                    
                    return {...i, hours} 
                     })
                     
                    //  console.log("adjust: ", dateCheck)

            setOutput(dateCheck)
        }
    }, [data])

    // console.log("chart data: ", output)
    
    const customTooltip = (arg) => {
        return {
            text: `Contract Hours: ${arg.value} <br>Served Hours :${arg.target}`
        };
    }

    return (
        <Card sx={{bgcolor: '#ECF1F7', m:1,p:1}}>
            <Typography className='title'>Contract Hours/ Served</Typography><br />
            <Typography className='title'>{title}</Typography>
            <Table className='table'>
                <TableHead>
                    <TableRow className='tableRow'>
                        <TableCell></TableCell>
                        {header &&
                        <>
                            <TableCell className='tableCell'>{header[0]}</TableCell>
                            <TableCell className='tableCell'>{header[1]}</TableCell>
                            <TableCell className='tableCell'>{header[2]}</TableCell>
                            <TableCell className='tableCell'>{header[3]}</TableCell>
                        </>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {output && output.map((i, idx) => (
                        <React.Fragment key={idx}>
                            <TableRow className='tableRow'>
                                <TableCell className='tableCell'>{i.fullName}</TableCell>
                                <TableCell className='tableCell'>
                                    <Bullet
                                        className="bullet"
                                        target={i.hours[0].count}
                                        value={i.hours[0].month}
                                        color={i.colors[0]}
                                    >
                                        <Tooltip customizeTooltip={customTooltip} />
                                    </Bullet>
                                </TableCell>
                                <TableCell className='tableCell'>
                                    <Bullet
                                        className="bullet"
                                        target={i.hours[1].count}
                                        value={i.hours[1].month}
                                        color={i.colors[1]}
                                    >
                                        <Tooltip customizeTooltip={customTooltip} />
                                    </Bullet>
                                </TableCell>
                                <TableCell className='tableCell'>
                                    <Bullet
                                        className="bullet"
                                        target={i.hours[2].count}
                                        value={i.hours[2].month}
                                        color={i.colors[2]}
                                    >
                                        <Tooltip customizeTooltip={customTooltip} />
                                    </Bullet>
                                </TableCell>
                                <TableCell className='tableCell'>
                                    <Bullet
                                        className="bullet"
                                        target={i.sum}
                                        value={i.contracts.total}
                                        color={i.colors[3]}
                                    >
                                        <Tooltip customizeTooltip={customTooltip} />
                                    </Bullet>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>		
        </Card>
    )
}

Snapshot.propTypes = {
    userId: PropTypes.any    
}

export default Snapshot;