import React, {useState, useEffect} from 'react';
import { useGetUserQuery } from '../redux/apiSlice';
// import { useDispatch } from 'react-redux';
import { useAuth } from '../utilities/useAuth';
import TodaysVisits from './dashboardviews/TodaysVisits';
import SubmittedPlans from './dashboardviews/SubmittedPlans';
import { Divider, Card, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import MissingSigns from './visitviews/MissingSigns';
// import { setAgencyDetails } from '../redux/slices/settingsSlice';



const Dashboard = () => {
	
	const { id, admin, supAdmin } = useAuth()
	const userId = id

	// const dispatch = useDispatch()

	// const {data: settings, isSuccess: settingsSuccess} = useGetAgencySettingsQuery();
	// console.log("settings loaded, dashboard?: ", settings)
	// if(settingsSuccess){
	// 	dispatch(setAgencyDetails(settings))
	// }

	const [hasAuth, setHasAuth] = useState(false)
	const [skip, setSkip] = useState(true)

	useEffect(() => {
		if (id?.length) {
			setHasAuth(true)
			setSkip(false)
		}
	}, [id])
	
	const {data: user, isSuccess: userSuccess, isLoading: userLoading, isError: userError} = useGetUserQuery(userId, {skip})
	

	let userDetail
	let adminDetail

	if(userSuccess) {
		userDetail = 
		<div display='flex' > 
			<h1 style={{ fontSize: '2em'}}>{user.fullName}</h1> <br />
				<br />
			<TodaysVisits hasAuth={hasAuth} userId={userId} />
			<Divider sx={{ m: 1, p: 1}} />
			<MissingSigns hasAuth={hasAuth} userId={userId} />
		</div>
	} else if (userLoading) {
		userDetail = <h2>Loading...</h2>
	} else if (userError) {
		userDetail = <h2>Error</h2>
	}

	if(admin || supAdmin) {
		adminDetail = <SubmittedPlans />
	}

	// const hasWidth = window.innerWidth
	// console.log("hasWidth: ", hasWidth)

	return (
		<div display='flex' >
			<Paper sx={{backgroundColor: '#ECF1F7' }}>
				<Grid container direction='column' spacing={3} >
					<Grid item xs='auto'>
						<Card sx={{m: 2, p: 2}}>
							{userDetail}
						</Card>
					</Grid>
					<Grid item xs='auto'>
						<Divider />
					</Grid>
					<Grid item xs='auto'>
						<Card sx={{m: 2, p: 2}}>
							{adminDetail}
						</Card>
					</Grid>
				</Grid>
			</Paper>
		
		</div>
	)



	}


export default Dashboard;