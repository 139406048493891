import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logOut, setCredentials } from './slices/authSlice';

console.log('base: ', process.env.REACT_APP_BASEURL)

const waitForToken = async (getState) => {
    let token = getState().auth.token;
    // console.log("waiting for token")

    while (!token) {
        await new Promise((resolve) => setTimeout(resolve, 50)); // Retry every 50ms
        token = getState().auth.token;
        // console.log("have token")
    }
    // console.log("token: ", token)
    return token;
};

const baseQueryWithReauthAndToken = async (args, api, extraOptions) => {
    const excludedUrls = [
        '/auth/login',
        '/auth/refresh',
        '/auth/register',
        '/auth/firstlogin',
        '/auth/forgotpassword',
        '/auth/resetpassword',
    ];

    // Extract the URL from args (whether it's a string or an object)
    const url = typeof args === 'string' ? args : args.url;

    // Check if the current URL is excluded from token wait logic
    const isExcludedUrl = excludedUrls.some((excludedUrl) => url.startsWith(excludedUrl));

    let token = null;
    if (!isExcludedUrl) {
        try {
            token = await waitForToken(api.getState); // Wait only if not an excluded URL
        } catch (error) {
            console.error('Error fetching token:', error);
            return { error: { status: 500, data: { message: 'Token retrieval failed.' } } };
        }
    }

    const baseQuery = fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BASEURL}`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    });

    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 403 && !isExcludedUrl) {
        console.log('Attempting to refresh token...');
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions);

        if (refreshResult?.data) {
            api.dispatch(setCredentials({ ...refreshResult.data }));
            result = await baseQuery(args, api, extraOptions); // Retry original request
        } else {
            if (refreshResult?.error?.status === 403) {
                api.dispatch(logOut());
                refreshResult.error.data.message = 'Your login has expired.';
            }
            return refreshResult;
        }
    }

    return result;
};





// const baseQuery = fetchBaseQuery({
//     baseUrl: `${process.env.REACT_APP_BASEURL}`,
//     credentials: 'include',
//     prepareHeaders: (headers, {getState}) => {
//         const token = await waitForToken(api.getState.auth.token);
//         // const token = getState().auth.token

//         if (token) {
//             headers.set("authorization", `Bearer ${token}`)
//         }
//         // console.log("Authheaders: ", headers)
//         return headers
//     }
// })

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//     // console.log(args) // request url, method, body
//     // console.log(api) // signal, dispatch, getState()
//     //console.log(extraOptions) // custom like {shout:true}

//     let result = await baseQuery(args, api, extraOptions)
//     // console.log("result: ", result)

//     //as an option, handle other status codes
//     if (result?.error?.status === 403) {
//         // console.log('sending refresh token')

//         //send refresh token to get new access token
//         const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)

//         if (refreshResult?.data) {

//             //store new token
//             api.dispatch(setCredentials({...refreshResult.data}))

//             //retry original query with new access token
//             result = await baseQuery(args, api, extraOptions)
//         } else {
//             if (refreshResult?.error?.status === 403) {
//                 refreshResult.error.data.message = "Your login has expired. "
//             }
//             return refreshResult
//         }

//     }

//        return result
// }

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauthAndToken,
    // baseQuery, //: fetchBaseQuery({ baseUrl: 'http://localhost:5000'}),
    tagTypes: ['Client', 'Contract', 'User', 'Caseload', 'Team', 'Visit', 'Bracket', 'Entry', 'Miles', 'Chart', 'Client Reports', 'Goals', 'Report Notes', 'Income', 'Contact', 'Allergy', 'Medication', 'Uploads', 'Settings', 'ClientStatus', 'Clock In', 'Summary', 'Timecard', 'AuditLog', 'Missing Signs', 'Logs'],
    endpoints: builder => ({
        //Auth endpoints
        login: builder.mutation({
            query: ({ values }) => ({
                url: '/auth/login',
                method: 'POST',
                body: { ...values},
                credentials: 'include'
            }),
            // async onQueryStarted(args, { dispatch, queryFulfilled}) {
            //     try {
            //         await queryFulfilled;
            //         await dispatch
            //     }
            // }

        }),
        sendLogout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST'
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    // const { data } = 
                    await queryFulfilled
                    // console.log("logout: ", data)
                    dispatch(logOut())
                    if (typeof api !== "undefined") {
                    dispatch(api.util.resetApiState()) // eslint-disable-line
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        refresh: builder.mutation({
            query: () => ({
                url: '/auth/refresh',
                method: 'GET'
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled}) {
                try {
                    const { data } = await queryFulfilled
                    const  accessToken  = data.token
                    dispatch(setCredentials( accessToken ))
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        register: builder.mutation({
            query: ({ values }) => ({
                url: '/auth/register',
                method: 'POST',
                body: values
            })
        }),
        firstLoginSetPass: builder.mutation({
            query: (arg) => {
                const {pass, userId} = arg;
                return {
                    url: `/auth/firstlogin/${userId}`,
                    method: 'POST',
                    body: pass
                }
            }
        }),
        forgotPassword: builder.mutation({
            query: (arg) => {
                console.log("email: ", arg)
                const {email} = arg
                return {
                url: '/auth/forgotpassword',
                method: 'POST',
                body: {email: email} 
            }
            }
        }),
        resetPassword: builder.mutation({
            query: (arg) => {
                const {resToken, password} = arg;
                return {
                url: `/auth/resetpassword/${resToken}`,
                method: 'POST',
                body: password
            }}
        }),

        //Client endpoints
        getClients:  builder.query({
            query: (arg) => {
                const {role, userId} = arg;
                return {
                    url: `/clientapi/clients/${role}/${userId}`,
                    method: 'GET'
                }
            },                
            providesTags: ['Client']
        }),
        getClient: builder.query({
            query: clientId => `/clientapi/${clientId}`,
            providesTags: ['Client']
        }),
        getClientsByName: builder.query({
            query: (inputText) => ({
                    url: `/clientapi/clients?firstName=${inputText}&lastName=${inputText}`
            })
        }),
        getCurrentPlan: builder.query({
            query: (clientId) => ({
                url: `/clientapi/${clientId}/plan`,
                responseHandler: (response) => response.text()
            })
        }),
        addNewClient: builder.mutation({
            query: (arg) => {
                const {data, isUser} = arg;
                return {
                url: '/clientapi/clients',
                method: 'POST',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Client']
        }),
        editClient: builder.mutation({
            query: (arg) => {
                const {clientId, data, isUser, moveOut} = arg;
                return {
                url: `/clientapi/${clientId}`,
                method: 'PATCH',
                body: {data, isUser, moveOut}
                }
            },
            invalidatesTags: ['Client']
        }),
        deleteClient: builder.mutation({
            query: (arg) => {
                const {clientId, isUser} = arg;
                return{
                url: `/clientapi/softDelete/${clientId}`,
                method: 'PATCH',
                body: isUser,
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags: ['Client']
        }),

        //Contract endpoints
        getContracts:  builder.query({
            
            query: (clientId) => {
            return {
             url:   `/clientapi/contracts/${clientId}`,
           
        }
        },
             providesTags: ['Contract']
            
        // (result = [], error, arg) => [
        //     'Contract',
        //     ...result.map(({ id }) => ({ type: 'Contract', id}))
        // ]
        
        // (result) =>
        //     result ? [
        //         ...result.map(({ id }) => ({ type: 'Contract', id})),
        //         { type: 'Contract', id: 'LIST'},
        //         ] : [{ type: 'Contract', id: 'LIST'}]
                
        //     }
    }),
        getContract: builder.query({
            query: (arg) => {
                const {clientId, contractId} = arg;
                return {
                url: `/clientapi/contracts/${clientId}/${contractId}`,
                method: 'GET'
                }
            },
            providesTags: ['Contract'] //(result, error, id) => [{ type: 'Contract', id }]
        }),
        addNewContract: builder.mutation({
            query: (arg) => {
                const {clientId, data, isUser} = arg;
                return {
                url: `/clientapi/contracts/${clientId}`,
                method: 'POST',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Contract']  // [{ type: 'Contract', id: 'LIST' }]
        }),
        editContract: builder.mutation({
            query: (arg) => {
                const {clientId, contractId, data, isUser} = arg;
                console.log("clientId: ", clientId, "contractId: ", contractId)
                return {
                url: `/clientapi/contracts/${clientId}/${contractId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags:['Contract'] //(result, error, id) => [{ type: 'Contract', id: id}]
        }),
        deleteContract: builder.mutation({
            query: (contractId) => {
                console.log(contractId)
                // const {clientId, contractId} = arg;
                return {
                url: `/clientapi/contracts/delete/${contractId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags:['Contract'] // [{ type: 'Contract', id: 'LIST' }]
        }),

        //Income endpoints
        getAllIncome:  builder.query({
            
            query: (clientId) => {
            return {
             url:   `/incomeapi/${clientId}`,
           
        }
        },
             providesTags: ['Income']
    }),
        getSingleIncome: builder.query({
            query: (arg) => {
                const {clientId, incomeId} = arg;
                return {
                url: `/incomeapi/${clientId}/${incomeId}`,
                method: 'GET'
                }
            },
            providesTags: ['Income'] //(result, error, id) => [{ type: 'Income', id }]
        }),
        addIncome: builder.mutation({
            query: (arg) => {
                const {clientId, data, isUser} = arg;
                return {
                url: `/incomeapi/${clientId}`,
                method: 'POST',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Income']  // [{ type: 'Income', id: 'LIST' }]
        }),
        editIncome: builder.mutation({
            query: (arg) => {
                const {clientId, incomeId, data, isUser} = arg;
                return {
                url: `/incomeapi/${clientId}/${incomeId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags:['Income'] //(result, error, id) => [{ type: 'Income', id: id}]
        }),
        deleteIncome: builder.mutation({
            query: (incomeId) => {
                // const {clientId, incomeId} = arg;
                console.log("income id: ", incomeId)
                return {
                url: `/incomeapi/delete/${incomeId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags:['Income'] // [{ type: 'Income', id: 'LIST' }]
        }),

        //Contact endpoints
        getAllContacts:  builder.query({
            
            query: (clientId) => {
            return {
             url:   `/contactapi/${clientId}`,
           
        }
        },
             providesTags: ['Contact']
    }),
        getSingleContact: builder.query({
            query: (arg) => {
                const {clientId, contactId} = arg;
                return {
                url: `/contactapi/${clientId}/${contactId}`,
                method: 'GET'
                }
            },
            providesTags: ['Contact'] //(result, error, id) => [{ type: 'Contact', id }]
        }),
        addContact: builder.mutation({
            query: (arg) => {
                const {clientId, data, isUser} = arg;
                return {
                url: `/contactapi/${clientId}`,
                method: 'POST',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Contact']  // [{ type: 'Contact', id: 'LIST' }]
        }),
        editContact: builder.mutation({
            query: (arg) => {
                const {clientId, contactId, data, isUser} = arg;
                return {
                url: `/contactapi/${clientId}/${contactId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags:['Contact'] //(result, error, id) => [{ type: 'Contact', id: id}]
        }),
        deleteContact: builder.mutation({
            query: (contactId) => {
                // const {clientId, contactId} = arg;
                return {
                url: `/contactapi/delete/${contactId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags:['Contact'] // [{ type: 'Contact', id: 'LIST' }]
        }),

        //Allergy endpoints
        getAllAllergies:  builder.query({
            
            query: (clientId) => {
            return {
             url:   `/clientapi/allergies/${clientId}`,
           
        }
        },
             providesTags: ['Allergy']
    }),
        getSingleAllergy: builder.query({
            query: allergyId => `/clientapi/allergies/${allergyId}`,
            providesTags: ['Allergy'] //(result, error, id) => [{ type: 'Allergy', id }]
        }),
        addAllergy: builder.mutation({
            query: (arg) => {
                const {clientId, data, isUser} = arg;
                return {
                url: `/clientapi/allergies/${clientId}`,
                method: 'POST',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Allergy']  // [{ type: 'Allergy', id: 'LIST' }]
        }),
        editAllergy: builder.mutation({
            query: (arg) => {
                const {clientId, allergyId, data, isUser} = arg;
                return {
                url: `/clientapi/allergies/${clientId}/${allergyId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags:['Allergy'] //(result, error, id) => [{ type: 'Allergy', id: id}]
        }),
        deleteAllergy: builder.mutation({
            query: (allergyId) => {
                // const {clientId, allergyId} = arg;
                return {
                url: `/clientapi/allergies/delete/${allergyId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags:['Allergy'] // [{ type: 'Allergy', id: 'LIST' }]
        }),
        
        //Medication endpoints
        getAllMedications:  builder.query({
            
            query: (clientId) => {
            return {
             url:   `/clientapi/medication/${clientId}`,           
        }
        },
             providesTags: ['Medication'],
             transformResponse: (response) => {
                // console.log("api response: ", response)
              const final = response.map((response) => {
                // console.log("response: ", response)
                let newEnd
                if (response.active ){
                    newEnd = null
                } else (
                    newEnd = response.endDate
                )
                   const obj =  {
                    _id: response._id,
                    name: response.name,
                    dosage: response.string,
                    frequency: response.frequency,
                    active: response.active,
                    reason: response.reason,
                    startDate: response.startDate,
                    endDate: newEnd,
                    }
                    return obj
            })
                // console.log("final: ", final)
                return final
                
            },
    }),
        getSingleMedication: builder.query({
            query: medsId => `/clientapi/medication/${medsId}`,
            providesTags: ['Medication'] //(result, error, id) => [{ type: 'Medication', id }]
        }),
        addMedication: builder.mutation({
            query: (arg) => {
                const {clientId, data, isUser} = arg;
                return {
                url: `/clientapi/medication/${clientId}`,
                method: 'POST',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Medication']  // [{ type: 'Medication', id: 'LIST' }]
        }),
        editMedication: builder.mutation({
            query: (arg) => {
                const {clientId, medsId, data, isUser} = arg;
                return {
                url: `/clientapi/medication/${clientId}/${medsId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags:['Medication'] //(result, error, id) => [{ type: 'Medication', id: id}]
        }),
        deleteMedication: builder.mutation({
            query: (medsId) => {
                // const {clientId, medsId} = arg;
                return {
                url: `/clientapi/medication/delete/${medsId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags:['Medication'] // [{ type: 'Medication', id: 'LIST' }]
        }),     
        
        //client status endpoints
        getClientStatus: builder.query({
            query: clientId => `/clientapi/status/${clientId}`,
            providesTags: ['ClientStatus']
        }),
        unenrollStatus: builder.mutation({
            query: (arg) => {
                const {clientId, enrollId, data, isUser} = arg;
                console.log("enrollId: ", enrollId)
                return {
                    url: `clientapi/status/unenroll/${clientId}/${enrollId}`,
                    method: 'PATCH',
                    body: {data, isUser}
                }
            },
            invalidatesTags: ['ClientStatus', 'Client']
        }),
        reenrollStatus: builder.mutation({
            query: (arg) => {
                const {clientId, data, isUser} = arg;
                return {
                    url: `clientapi/status/reenroll/${clientId}`,
                    method: 'PATCH',
                    body: {data, isUser}
                }
            },
            invalidatesTags: ['ClientStatus', 'Client']
        }),

        //client log endpoints
getAllClientLogs: builder.query({
    query: (clientId) => `/clientapi/logs/getall/${clientId}`,
    providesTags: (result, error, clientId) => [{ type: 'Logs', id: clientId }],
}),
getLogsByType: builder.query({
    query: (arg) => {
        const { clientId, type } = arg;
        return {
            url: `clientapi/logs/getByType/${clientId}/${type}`,
            method: 'GET',
        };
    },
    providesTags: (result, error, arg) => [{ type: 'Logs', id: arg.clientId }],
}),
getSingleLog: builder.query({
    query: (arg) => {
        const { clientId, logId } = arg;
        return {
            url: `clientapi/logs/getSingle/${clientId}/${logId}`,
            method: 'GET',
        };
    },
    providesTags: (result, error, arg) => [{ type: 'Logs', id: arg.clientId }, { type: 'Log', id: arg.logId }],
}),
getLogsForVisit: builder.query({
    query: (clientId) => `clientapi/logs/getLogsForVisit/${clientId}`
}),
addHealthLogType: builder.mutation({
    query: (arg) => {
        const { clientId, data, isUser } = arg;
        return {
            url: `clientapi/logs/addHealthType/${clientId}`,
            method: 'POST',
            body: { data, isUser },
        };
    },
    invalidatesTags: (result, error, arg) => [{ type: 'Logs', id: arg.clientId }],
}),
addNewLog: builder.mutation({
    query: (arg) => {
        const { clientId, logType, data, isUser, logId } = arg;
        return {
            url: `clientapi/logs/addNew/${clientId}`,
            method: 'POST',
            body: { data, logType, isUser, logId },
        };
    },
    invalidatesTags: (result, error, arg) => [{ type: 'Logs', id: arg.clientId }],
}),
editLog: builder.mutation({
    query: (arg) => {
        const { clientId, logId, data, logType, isUser, entryId } = arg;
        return {
            url: `clientapi/logs/editLog/${clientId}/${logId}`,
            method: 'PATCH',
            body: { data, logType, isUser, entryId },
        };
    },
    invalidatesTags: (result, error, arg) => [{ type: 'Logs', id: arg.clientId }, { type: 'Log', id: arg.logId }],
}),
softDeleteLog: builder.mutation({
    query: (arg) => {
        const { clientId, id, logType, isUser, entryId } = arg;
        return {
            url: `clientapi/logs/softDelete/${clientId}/${id}`,
            method: 'PATCH',
            body: { logType, isUser, entryId },
            responseHandler: (response) => response.text(),
        };
    },
    invalidatesTags: (result, error, arg) => [{ type: 'Logs', id: arg.clientId }, { type: 'Log', id: arg.id }],
}),
hardDeleteLog: builder.mutation({
    query: (arg) => {
        const { clientId, logId, logType, entryId } = arg;
        return {
            url: `clientapi/logs/hardDelete/${clientId}/${logId}`,
            method: 'DELETE',
            body: { logType, entryId },
            responseHandler: (response) => response.text(),
        };
    },
    invalidatesTags: (result, error, arg) => [{ type: 'Logs', id: arg.clientId }, { type: 'Log', id: arg.logId }],
}),


        //User endpoints
        getUsers:  builder.query({
            query: () => '/userapi/users',
            providesTags: ['User']
        }),
        getUserNames: builder.query({
            query: () => 'userapi/users/usernames'
        }),
        getUser: builder.query({
            query: userId => `/userapi/users/${userId}`,
            providesTags: ['User']
        }),
        userTimechartSearch: builder.mutation({
            query: (arg) => {
                const {userId, isStart, isEnd} = arg;
                return {
                    url: `/userapi/chartsearch/${userId}`,
                    method: 'POST',
                    body: {isStart, isEnd}
                }
            },
            providesTags: ['Chart', 'Bracket', 'Visit'],
            //invalidatesTags: ['Chart']
        }),
        getUsersByRole: builder.query({
            query: (role) => `/userapi/users/role/${role}`,
            providesTags: ['User']
        }),
        getSnapshot: builder.mutation({
            query: (arg) => {
                const {isStart, isEnd, userId} = arg;
                return {
                    url: `/userapi/users/snapshot/${userId}`,
                    method: 'POST',
                    body: {isStart, isEnd}
                }
            }
        }),
        getUserGoalReport: builder.mutation({
            query: (arg) => {
                const {isStart, isEnd, userId} = arg;
                return {
                    url: `/userapi/users/goalreport/${userId}`,
                    method: 'POST',
                    body: {isStart, isEnd}
                }
            }
        }),
        addNewUser: builder.mutation({
            query: (arg) => {
                const {data, isUser} = arg;
                return {
                    url: '/userapi/users',
                    method: 'POST',
                    body: {data, isUser}
                }
            },
            invalidatesTags: ['User']
        }),
        editUser: builder.mutation({
            query: (arg) => {
                const {userId, data, isUser} = arg;
                // console.log("userId: ", userId, data);
                return {
                url: `/userapi/users/${userId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['User']
        }),
        deleteUser: builder.mutation({
            query: (arg) => {
                // console.log("arg: ", arg)
                const { userId, isUser} = arg;
                return {
                url: `/userapi/softDelete/${userId}/${isUser}`,
                method: 'PATCH',
                // body: isUser,
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags: ['User']

        }),

        suspendUser: builder.mutation({
            query: (arg) => {
                const {userId, data, isUser} = arg;
                // console.log("susp data: ", data)
                return {
                    url: `/userapi/suspend/${userId}`,
                    method: 'PATCH',
                    body: {data, isUser}
                }
            },
            invalidatesTags: ['User']
        }),

        reinstateUser: builder.mutation({
            query: (arg) => {
                const {userId, suspendId, data, isUser} = arg;
                // console.log("rein data: ", suspendId, data)
                return {
                    url: `/userapi/reinstate/${userId}/${suspendId}`,
                    method: 'PATCH',
                    body: {data, isUser}
                }
            },
            invalidatesTags: ['User']
        }),

        //caseload endpoints
        getClientsByUserId: builder.query({
            query: (userId) => {
                // console.log("api slice: ", userId)
                return {
                url: `/userapi/caseload/${userId}`
            }},
            providesTags: ['Caseload']
        }),
        getUsersByClientId: builder.query({
            query: (clientId) => ({
                url: `userapi/caseload/clients/${clientId}`
            }),
            providesTags: ['Caseload']
        }),
        addClientToUser: builder.mutation({
            query: (arg) => {
                const {userId, final} = arg;
                return {
                    url: `/userapi/caseload/${userId}`,
                    method: 'POST',
                    body: final
                }
            },
            invalidatesTags: ['Caseload']
        }),
        addUserToClient: builder.mutation({
            query: (arg) => {
                const {clientId, data} = arg;
                return {
                    url: `/userapi/caseload/clients/${clientId}`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Caseload']
        }),
        removeClientFromUser: builder.mutation({
            query: (arg) => {
                const {userId, clientId} = arg;
                return {
                url: `/userapi/caseload/${userId}/${clientId}`,
                method: 'DELETE',
                }
            },
            invalidatesTags: ['Caseload']
        }),

        //team endpoints
        getTeamMembers: builder.query({
            query: (superId) => ({
                url: `/userapi/team/${superId}`
            }),
            providesTags: ['Team']
        }),
        getTeamClients: builder.query({
            query: (superId) => ({
                url: `/userapi/team/clients/${superId}`
            }),
            providesTags: ['Team']
        }),
        addUserToTeam: builder.mutation({
            query: (arg) => {
                const {superId, final} = arg;
                console.log("api: ", final)
                return {
                    url: `/userapi/team/${superId}`,
                    method: 'POST',
                    body: final
                }
            },
            invalidatesTags: ['Team']
        }),
        removeUserFromTeam: builder.mutation({
            query: (arg) => {
                const {userId, superId} = arg;
                return {
                url: `/userapi/team/${superId}/${userId}`,
                method: 'DELETE',
                }
            },
            invalidatesTags: ['Team']
        }),

        //Visit endpoints
        getVisits:  builder.query({
            query: () => '/visitapi/visits',
            providesTags: ['Visit']
        }),
        getVisitsByUserId: builder.query({
            query: userId => `/visitapi/visits/users/${userId}`,
            providesTags: ['Visit', 'User']
        }),
        getScheduleByDate: builder.query({
            query: (arg) => {
                const {userId, isStart, isEnd} = arg;
                return {
                    url: `/visitapi/datesearch/users/${userId}?visitStart=${isStart}&visitEnd=${isEnd}`,
                    method: 'GET',
                    params: {isStart, isEnd}
                }
            },
            providesTags: ['Visit', 'User'],
            transformResponse: (response) => {
                // console.log("api response: ", response)
              const final = response.map((response) => {
                // console.log("response: ", response)
                let setColor
                if(response.status === 'Scheduled'){
                    setColor = 'blue'
                } else if (response.status === 'Completed'){
                    setColor = '#32CD32'
                } else if (response.status === 'Canceled'){
                    setColor = 'orange'
                } else if (response.status === 'On-Site'){
                    setColor = '#00FFFF'
                }
                   const obj =  {
                        id: response._id,
                        start: new Date(response.visitStart),
                        end: new Date(response.visitEnd),
                        title: response.client?.fullName,
                        location: response.location,
                        visitId: response._id,
                        visitStart: response.visitStart,
                        visitEnd: response.visitEnd,
                        client: response.client,
                        goals: response.goals,
                        status: response.status,
                        clientSig: response.clientSig,
                        cancelNote: response.cancelNote,
                        sigToken: response.sigToken,
                        color: setColor
                    }
                    return obj
            })
                return final
                
            },
        }),
        getUserScheduleVisits: builder.query({
            query: userId => `/visitapi/visits/users/${userId}`,
            providesTags: ['Visit', 'User'],
            transformResponse: (response) => {
                // console.log("api response: ", response)
              const final = response.map((response) => {
                // console.log("response: ", response)
                let setColor
                if(response.status === 'Scheduled'){
                    setColor = 'blue'
                } else if (response.status === 'Completed'){
                    setColor = '#32CD32'
                } else if (response.status === 'Canceled'){
                    setColor = 'orange'
                } else if (response.status === 'On-Site'){
                    setColor = '#00FFFF'
                }
                   const obj =  {
                        id: response._id,
                        start: new Date(response.visitStart),
                        end: new Date(response.visitEnd),
                        title: response.client?.fullName,
                        location: response.location,
                        visitId: response._id,
                        visitStart: response.visitStart,
                        visitEnd: response.visitEnd,
                        client: response.client,
                        goals: response.goals,
                        status: response.status,
                        clientSig: response.clientSig,
                        cancelNote: response.cancelNote,
                        sigToken: response.sigToken,
                        color: setColor
                    }
                    return obj
            })
                return final
                
            },
        }),
        getVisitsByClientId: builder.query({
            query: clientId => `/visitapi/visits/clients/${clientId}`,
            providesTags: ['Visit', 'Client']
        }),
        getVisit: builder.query({
            query: visitId => `/visitapi/visits/${visitId}`,
            keepUnusedDataFor: 1,
            providesTags: ['Visit']
        }),
        getVisitsOfUserByDate: builder.query({
            query: (arg) => {
                const {userId, isStart, isEnd} = arg;
                return {
                    url: `/visitapi/datesearch/users/${userId}?visitStart=${isStart}&visitEnd=${isEnd}`,
                    method: 'GET',
                    params: {isStart, isEnd}
                }
            },
            providesTags: ['Visit']
        }),
        getVisitsOfClientByDate: builder.query({
            query: (arg) => {
                const {clientId, isStart, isEnd} = arg;
                return {
                    url: `/visitapi/datesearch/clients/${clientId}?visitStart=${isStart}&visitEnd=${isEnd}`,
                    method: 'GET',
                    params: {isStart, isEnd}
                }
            },
            providesTags: ['Visit']
        }),
        clientVisitsSearch: builder.mutation({
            query: (arg) => {
                const {clientId, isStart, isEnd, userId} = arg;
                return {
                    url: `visitapi/searchoptions/${clientId}`,
                    method: 'POST',
                    body: {userId, isStart, isEnd}
                }
            }
        }),
        getMissingSigns: builder.query({
            query: userId => `/visitapi/missingSigns/${userId}`,
            providesTags: ['Missing Signs']
        }),
        progNotesSearch: builder.mutation({
            query: (arg) => {
                const {clientId, userId, isStart, isEnd} = arg;
                return {
                    url: `visitapi/prognotes/${clientId}`,
                    method: 'POST',
                    body: {userId, isStart, isEnd}
                }
            }
        }),
        visitChartsearch: builder.mutation({
            query: (arg) => {
                const {userId, isStart, isEnd} = arg;
                return {
                    url: `/visitapi/chartsearch/${userId}`,
                    method: 'POST',
                    body: {isStart, isEnd}
                }
            }
        }),
        getGoalAttainData: builder.mutation({
            query: (arg) => {
                const {clientId, isStart, isEnd, planId} = arg;
                return {
                    url: `visitapi/goalsreport/${clientId}`,
                    method: 'POST',
                    body: {isStart, isEnd, planId}
                }
            }
        }),
        addNewVisit: builder.mutation({
            query: ({ data, isUser }) => ({
                url: '/visitapi/visits',
                method: 'POST',
                body: {data, isUser}
            }),
            invalidatesTags: ['Visit']
        }),
        editVisit: builder.mutation({
            query: (arg) => {
                const {visitId, data, isUser} = arg;
                console.log("visit: ", visitId, data)
                return {
                url: `/visitapi/visits/${visitId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            keepUnusedDataFor: 1,
            invalidatesTags:['Visit', 'Missing Signs']
        }),
        deleteVisit: builder.mutation({
            query: (arg) => {
                const {visitId, isUser} = arg;
                return {
                url: `/visitapi/visits/softDelete/${visitId}`,
                method: 'PATCH',
                body: isUser,
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags: ['Visit']
        }),

        getVisitsForBrackets: builder.query({
            query: (arg) => {
                const {userId, isStart, isEnd} = arg;
                return {
                    url: `/timeapi/time/visits/${userId}?isStart=${isStart}&isEnd=${isEnd}`,
                    method: 'GET'
                }
            },
            providesTags: ['Bracket', 'Visit']
        }),

        //TimeBracket endpoints
        getBracketsOfUserByDate: builder.query({
            query: (arg) => {
                const {userId, startDate, endDate} = arg;
                return {
                    url: `/timeapi/datesearch/${userId}?startDate=${startDate}&endDate=${endDate}`,
                    method: 'GET'
                }
            },
            providesTags: ['Bracket', 'Entry', 'Miles']
        }),
        getAllTimeBrackets: builder.query({
            query: () => `timeapi/time`,
            providesTags: ['Bracket', 'Entry', 'Miles']
        }),
        getTimeBracketById: builder.query({
            providesTags: ['Bracket', 'Entry', 'Miles']
        }),
        getTimeBracketByUserId: builder.query({
            query: userId => `/timeapi/time/users/${userId}`,
            providesTags: ['Bracket', 'Entry', 'Miles']
        }),
        getUserClockInStatus: builder.query({
            query: (arg) => {
                const {userId, today} = arg;
                return {
                    url: `/timeapi/clockstatus/${userId}?date=${today}`,
                    method: 'GET'
                }
            },
            providesTags: ['Clock In']
        }),
        bracketChartsearch: builder.mutation({
            query: (arg) => {
                const {userId, isStart, isEnd} = arg;
                return {
                    url: `/timeapi/chartsearch/${userId}`,
                    method: 'POST',
                    body: {isStart, isEnd}
                }
            }
        }),
        addNewTimeBracket: builder.mutation({
            query: ({ data }) => ({
                url: '/timeapi/time',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Bracket']
        }),
        updateTimeBracket: builder.mutation({
            query: (arg) => {
                const {bracketId, data} = arg;
                return {
                    url: `/timeapi/time/${bracketId}`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: ['Bracket']
        }),
        deleteTimeBracket: builder.mutation({
            query: (bracketId) => ({
                url: `timeapi/time/${bracketId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
            }),
            invalidatesTags: ['Bracket']
        }),




        //TimeEntry endpoints
        getTimeEntryByBracketId: builder.query({
            query: bracketId => `timeapi/time/${bracketId}/entries`,
            providesTags: ['Entry']
        }),
        getTimeEntryById: builder.query({
            query: (arg) => {
                const {bracketId, entryId} = arg;
                return{
                    url: `timeapi/time/${bracketId}/entries/${entryId}`,
                    method: 'GET'
                }
            }, 
            providesTags: ['Entry']
        }),
        getTimeEntryByUserId: builder.query({
            query: userId => `timeapi/time/user/${userId}`,
            providesTags: ['Entry']
        }),
        getTimeEntryByDate: builder.query({
            query: (arg) => {
                const {userId, start, end} = arg;
                console.log(userId, start, end)
                return {
                    url: `/timeapi/time/user/${userId}?timeIn=${start}&timeOut=${end}`,
                    method: 'GET'
                }
            }
        }),
        addNewTimeEntry: builder.mutation({
            query: (arg) => {
                const { bracketId, data } = arg;
                return {
                    url: `/timeapi/time/${bracketId}/entries`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Entry', 'Bracket']
        }),
        startUserClock: builder.mutation({
            query: (arg) => {
                const {bracketId, data} = arg;
                return {
                    url: `/timeapi/time/${bracketId}/startclock`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Entry', 'Bracket']
        }),
        editTimeEntry: builder.mutation({
            query: (arg) => {
                const {bracketId, entryId, data} = arg;
                console.log("entryId: ", entryId)
                return {
                url: `/timeapi/time/${bracketId}/entries/${entryId}`,
                method: 'PATCH',
                body: data
                }
            },
            invalidatesTags:['Entry', 'Bracket']
        }),
        stopUserClock: builder.mutation({
            query: (arg) => {
                const {bracketId, entryId, data} = arg;
                return {
                    url: `timeapi/time/${bracketId}/stopClock/${entryId}`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: ['Entry', 'Bracket']
        }),
        deleteTimeEntry: builder.mutation({
            query: (entryId) => {
                // const {bracketId, entryId} = arg;
                return {
                url: `/timeapi/time/entries/delete/${entryId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags: ['Entry', 'Bracket']
        }),

        //Miles endpoints
        getMilesByBracket: builder.query({
            query: (entryId) => {
            return {
             url:   `/timeapi/time/${entryId}/miles`,
        }
        },
             providesTags: ['Miles']
        }),
        
        // getMilesByUserId: builder.query({

        //     query: (arg) => {
        //         const {bracketId, userId} = arg;
        //         return {
        //         url: `/timeapi/time/${bracketId}/contracts/${contractId}`,
        //         method: 'GET'
        //         }
        //     },
        //     providesTags: [{ type: 'User', id: 'MILES'}] 
        // }),
        addNewMiles: builder.mutation({
            query: (arg) => {
                const {bracketId, data} = arg;
                return {
                url: `/timeapi/time/${bracketId}/miles`,
                method: 'POST',
                body: data
                }
            },
            invalidatesTags: ['Miles']
        }),
        editMiles: builder.mutation({
            query: (arg) => {
                const {bracketId, milesId, data} = arg;
                console.log("bracketId: ", bracketId, "milesId: ", milesId)
                return {
                url: `/timeapi/time/${bracketId}/miles/${milesId}`,
                method: 'PATCH',
                body: data
                }
            },
            invalidatesTags: ['Miles']
        }),
        deleteMiles: builder.mutation({
            query: (milesId) => {
                // const {bracketId, milesId} = arg;
                return {
                url: `/timeapi/time/miles/delete/${milesId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags:['Miles']
        }),

        //admin reports endpoints
        getUserAttendance: builder.query({
            query: (arg) => {
                const {userId, isStart, isEnd} = arg;
                return{
                    url: `/visitapi/datesearch/users/${userId}?visitStart=${isStart}&visitEnd=${isEnd}`,
                    method: 'GET',
                    params: {isStart, isEnd}
                }
            },
            transformResponse: (response) => {
                
                return  response.map((response) => (
                       {
                          client: {
                                id: response.client._id,
                                fullName: response.client.fullName
                          },
                          start: new Date(response.visitStart).getDate(),
                          end: new Date(response.visitEnd).getDate(),
                          hours: response.totalHours
                      }
                  ))
                  
              },
        }),
        // getUserGoalsReport: builder.query({
        //     query: (arg) => {
        //         const {userId, isStart, isEnd} = arg;
        //         return{
        //             url: `/visitapi/datesearch/users/${userId}?visitStart=${isStart}&visitEnd=${isEnd}`,
        //             method: 'GET',
        //             params: {isStart, isEnd}
        //         }
        //     },
        //     transformResponse: (response) => {
                
        //         return  response.map((response) => (
        //                {
        //                   client: {
        //                         id: response.client._id,
        //                         fullName: response.client.fullName
        //                   },
        //                   start: new Date(response.visitStart).getDate(),
        //                   end: new Date(response.visitEnd).getDate(),
        //                   goals: response.goals
        //               }
        //           ))
                  
        //       },
        // }),
        //service plan endpoints
        getAllClientReports: builder.query({
            query: (clientId) => {
                return {
                 url:   `/planapi/plan/getAll/${clientId}`,
            }
            },
            providesTags: ['Client Reports']
        }),
        getClientReport: builder.query({
            query: (planId) => {
                
                return {
                    url: `/planapi/plan/getOne/${planId}`
                }
            },
            providesTags: ['Client Reports', 'Goals', 'Report Notes']
        }),
        getReportsToApprove: builder.query({
            query: () => '/planapi/plan/toApprove'
        }),
        getClientReportByDate: builder.query({
            query: (arg) => {
                const {clientId, searchStart, searchEnd} = arg;
                return {
                    url: `/planapi/plan/getByDate/${clientId}?searchStart=${searchStart}&searchEnd=${searchEnd}`,
                    method: 'GET',
                    // params: {searchStart, searchEnd}
                }
            },
            providesTags: ['Client Reports']
        }),
        addClientReport: builder.mutation({
            query: (arg) => {
                const {clientId, data, isUser} = arg;
                return {
                url: `/planapi/plan/add/${clientId}`,
                method: 'POST',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Client Reports'] 
        }),
        addStepperReport: builder.mutation({
            query: (arg) => {
                const { plan, isUser} = arg;
                return {
                url: `/planapi/plan/stepper`,
                method: 'POST',
                body: {plan, isUser}
                }
            }
        }),
        editClientReport: builder.mutation({
            query: (arg) => {
                const { planId, data, isUser} = arg;
                return {
                url: `/planapi/plan/edit/${planId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags:['Client Reports']
        }),
        updateReportStatus: builder.mutation({
            query: (arg) => {
                const { planId, data, isUser} = arg;
                return {
                url: `/planapi/plan/status/${planId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags:['Client Reports']
        }),
        deleteClientReport: builder.mutation({
            query: (arg) => {
               const { planId, isUser } = arg;
                return {
                url: `/planapi/plan/softDelete/${planId}`,
                method: 'PATCH',
                body: isUser,
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags: ['Client Reports']
        }),

        //goal endpoints
        getClientReportGoals: builder.query({
            query: planId => `/planapi/goals/${planId}/`,
            providesTags: ['Goals']
        }),
        getSingleGoal: builder.query({
            query: (arg) => {
                const {planId, goalId} = arg;
                return {
                url: `/planapi/goals/${planId}/${goalId}`,
                method: 'GET'
                }
            },
            providesTags: ['Goals'] //(result, error, id) => [{ type: 'Contract', id }]
        }),
        addGoal: builder.mutation({
            query: (arg) => {
                const {planId, data, isUser} = arg;
                return {
                url: `/planapi/goals/${planId}`,
                method: 'POST',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Goals', 'Client Reports']  // [{ type: 'Contract', id: 'LIST' }]
        }),
        editGoal: builder.mutation({
            query: (arg) => {
                const {planId, goalId, data, isUser} = arg;
                return {
                url: `/planapi/goals/${planId}/${goalId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags:['Goals', 'Client Reports', 'Report Notes'] //(result, error, id) => [{ type: 'Contract', id: id}]
        }),
        deleteGoal: builder.mutation({
            query: (arg) => {
                const {planId, goalId} = arg;
                return {
                url: `/planapi/goals/${planId}/${goalId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags:['Goals'] // [{ type: 'Contract', id: 'LIST' }]
        }),

        //reports notes endpoints
        getClientReportNotes: builder.query({
            query: (planId) => {
                return {
                 url:   `/planapi/notes/${planId}/`,
               
            }
            },
                 providesTags: ['Report Notes']
        }),
        editNotes: builder.mutation({
            query: (arg) => {
                const {planId, data, isUser} = arg;
                return {
                url: `/planapi/notes/${planId}`,
                method: 'POST',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Report Notes', 'Cluploadapiient Reports']  // [{ type: 'Contract', id: 'LIST' }]
        }),
        deleteNotes: builder.mutation({
            query: (planId) => {
                return {
                url: `/planapi/notes/${planId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags:['Report Notes'] // [{ type: 'Contract', id: 'LIST' }]
        }),

        //upload endpoints
        getUploadsByType: builder.query({
            query: (arg) => {
                const {clientId, type} = arg;
                return {
                    url: `/uploadapi/getByType/${clientId}?type=${type}`,
                    method: 'GET'
                }
            },
            providesTags: ['Uploads']
        }),
        getUploadById: builder.query({
            query: uploadId => `/uploadapi/getById/${uploadId}`,
            providesTags: ['Uploads'] 
        }),
        getAgencyConsents: builder.query({
            query: (agencyId) => {
                // console.log("agencyId: ", agencyId)
                return{
                    url: `uploadapi/getAgencyConsents/${agencyId}`,
                    method: 'GET',
                    // body: consentIds
                }
            },
            providesTags: ['Settings', 'Uploads']
        }),
        addUpload: builder.mutation({
            query: (arg) => {
                const {data, isUser} = arg;
                console.log("addUpload data: ", data)
                return {
                url: `/uploadapi/addNew`,
                method: 'POST',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Uploads'] 
        }),
        editUpload: builder.mutation({
            query: (arg) => {
                const {uploadId, data, isUser} = arg;
                return {
                url: `/uploadapi/edit/${uploadId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Uploads'] 
        }),
        signUpload: builder.mutation({
            query: (arg) => {
                const {uploadId, data, isUser} = arg;
                return {
                url: `/uploadapi/sign/${uploadId}`,
                method: 'PATCH',
                body: {data, isUser}
                }
            },
            invalidatesTags: ['Uploads'] 
        }),
        deleteUpload: builder.mutation({
            query: (uploadId) => {
                return {
                url: `/uploadapi/delete/${uploadId}`,
                method: 'DELETE',
                responseHandler: (response) => response.text()
                }
            },
            invalidatesTags: ['Uploads']
        }),
        getAgencySettings: builder.query({
            query: () => '/settingsapi/get',
            providesTags: ['Settings']
        }),
        getAgencyStats: builder.query({
            query: () => '/settingsapi/getStats',
            providesTags: ['Clients', 'Users', 'Visits', 'Uploads']
        }),
        pushDocToClients: builder.mutation({
            query: (data) => {
                console.log("data: ", data)
                return {
                    url: '/settingsapi/pushDoc',
                    method: 'POST',
                    body: {data}
                }
            },
            invalidatesTags: ['Uploads']
        }),
        addSettings: builder.mutation({
            query: (data) => {
                return {
                    url: 'settingsapi/add',
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Settings']
        }),
        editSettings: builder.mutation({
            query: (arg) => {
                const {agencyId, data} = arg;
                return {
                    url: `settingsapi/edit/${agencyId}`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: ['Settings']
        }),

        //timecard endpoints
        getAllTimecards: builder.query({
            query: () => '/timecardapi/getAll',
            providesTags: ['Timecard']
        }),
        getSingleTimecard: builder.query({
            query: (timecardId) => `/timecardapi/getOne/${timecardId}`,
            providesTags: ['Timecard']
        }),
        getTimecardsByUser: builder.query({
            query: (userId) => `/timecardapi/getByUserId/${userId}`,
            providesTags: ['Timecard']
        }),
        getTimecardsBySearch: builder.mutation({
            query: (body) => ({
                url: '/timecardapi/getSearch',
                method: 'POST',
                body: body
            }),
            providesTags: ['Timecard']
        }),
        userTimecardData: builder.query({
            query: (arg) => {
                const {userId, startDate, endDate} = arg;
                return {
                    url: `/timecardapi/timecardData/${userId}?startDate=${startDate}&endDate=${endDate}`,
                    method: 'GET'
                }
            },
            providesTags: ['Bracket', 'Entry', 'Miles', 'Summary']
        }),
        userTimecardSummary: builder.mutation({
            query: (arg) => {
                const {userId, isStart, isEnd, useStd} = arg;
                return {
                    url: `/timecardapi/timecardSummary/${userId}`,
                    method: 'POST',
                    body: {isStart, isEnd, useStd}
                }
            },
            transformResponse: (response) => {
                    console.log("response: ", response)
                const first =  response.map((response) => (
                       {
                          entryHours: response.entryHours,
                          visitHours: response.visitHours,
                          miles: response.miles,
                          date: new Date(response.date).toLocaleDateString(),
                          entryData: response.entryData
                      }
                  ))
                  console.log("first: ", first)
                
                  function scrub(object) {
                    Object
                        .entries(object)
                        .forEach(([k, v]) => {
                            if (v && typeof v === 'object') {
                                scrub(v);
                            }
                            if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
                                if (Array.isArray(object)) {
                                    object.splice(k, 1);
                                } else {
                                    delete object[k];
                                }
                            }
                        });
                    return object;
                }
                
                let cleaned = []
                first.forEach(i => {
                /* 	console.log(i); */
                  scrub(i)
                  /* console.log("2: ", i) */
                  cleaned.push(i)
                })
                
                console.log("cleaned: ", cleaned)

                const map = new Map(cleaned.map(item => [item.date, {}]));
                for (const item of cleaned) Object.assign(map.get(item.date), item);
                const merged = [...map.values()];

                console.log("merged: ", merged)

                const final = merged.sort(function(a,b){
                    return new Date(a.date) - new Date(b.date);
                  })
                
                return final
                
                  
              },
            providesTags: ['Chart', 'Summary']
        }),
        submitTimecard: builder.mutation({
            query: (data) => ({
                url: '/timecardapi/submit',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Timecard']
        }),
        approveTimecard: builder.mutation({
            query: (arg) => {
                const {timecardId, data} = arg;
                return {
                    url: `/timecardapi/approve/${timecardId}`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: ['Timecard']
        }),
        deleteTimecard: builder.mutation({
            query: (timecardId) => {
                return {
                    url: `/timecardapi/delete/${timecardId}`,
                    method: 'DELETE',
                    responseHandler: (response) => response.text()
                    }
            },
            invalidatesTags: ['Timecard']
        }),

        //audit log endpoints
        getAllAudits: builder.query({
            query: () => '/auditapi/getAll',
            providesTags: ['AuditLog']
        }),
        getAuditsBySearch: builder.mutation({
            query: (arg) => {
                const {startDate, endDate, userId} = arg;
                return {
                    url: '/auditapi/getBySearch',
                    method: 'POST',
                    body: {startDate, endDate, userId}
                }
            },
            providesTags: ['AuditLog']
        }),
        undoDelete: builder.mutation({
            query: (arg) => {
                const {type, itemId, auditId} = arg;
                return {
                    url: `/auditapi/undoDelete/${auditId}`,
                    method: 'PATCH',
                    body: {type, itemId}
                }
            },
            invalidatesTags: ['AuditLog', 'User', 'Client']
        })
        
    })
})

export const {
    //auth hooks
    useLoginMutation,
    useRegisterMutation,
    useFirstLoginSetPassMutation,
    useSendLogoutMutation,
    useRefreshMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,

    //client hooks
    useGetClientsQuery,
    useGetClientQuery,
    useGetCurrentPlanQuery,
    useAddNewClientMutation,
    useEditClientMutation,
    useDeleteClientMutation,

    //contracts hooks
    useGetContractsQuery,
    useGetContractQuery,
    useAddNewContractMutation,
    useEditContractMutation,
    useDeleteContractMutation,

    //income hooks
    useGetAllIncomeQuery,
    useGetSingleIncomeQuery,
    useAddIncomeMutation,
    useEditIncomeMutation,
    useDeleteIncomeMutation,

    //contact hooks
    useGetAllContactsQuery,
    useGetSingleContactQuery,
    useAddContactMutation,
    useEditContactMutation,
    useDeleteContactMutation,

    //allergy hooks
    useGetAllAllergiesQuery,
    useGetSingleAllergyQuery,
    useAddAllergyMutation,
    useEditAllergyMutation,
    useDeleteAllergyMutation,

    //meds hooks
    useGetAllMedicationsQuery,
    useGetSingleMedicationQuery,
    useAddMedicationMutation,
    useEditMedicationMutation,
    useDeleteMedicationMutation,

    //client logs hooks
    useGetAllClientLogsQuery,
    useGetLogsByTypeQuery,
    useGetSingleLogQuery,
    useGetLogsForVisitQuery,
    useAddHealthLogTypeMutation,
    useAddNewLogMutation,
    useEditLogMutation,
    useSoftDeleteLogMutation,
    useHardDeleteLogMutation,

    //client status hooks
    useGetClientStatusQuery,
    useUnenrollStatusMutation,
    useReenrollStatusMutation,

    //users hooks
    useGetUsersQuery,
    useGetUserNamesQuery,
    useGetUserQuery,
    useGetUsersByRoleQuery,
    useGetSnapshotMutation,
    useGetUserGoalReportMutation,
    useAddNewUserMutation,
    useEditUserMutation,
    useSuspendUserMutation,
    useReinstateUserMutation,
    useDeleteUserMutation,
    useUserTimechartSearchMutation,
    
    //caseload hooks
    useGetClientsByUserIdQuery,
    useGetUsersByClientIdQuery,
    useAddClientToUserMutation,
    useAddUserToClientMutation,
    useRemoveClientFromUserMutation,

    //team hooks
    useGetTeamMembersQuery,
    useGetTeamClientsQuery,
    useAddUserToTeamMutation,
    useRemoveUserFromTeamMutation,

    //visits hooks
    useGetVisitsQuery,
    useGetVisitsByUserIdQuery,
    useGetVisitsByClientIdQuery,
    useGetVisitQuery,
    useGetVisitsOfUserByDateQuery,
    useClientVisitsSearchMutation,
    useGetMissingSignsQuery,
    useGetVisitsOfClientByDateQuery,
    useGetScheduleByDateQuery,
    useGetUserScheduleVisitsQuery,
    useGetGoalAttainDataMutation,
    useAddNewVisitMutation,
    useEditVisitMutation,
    useDeleteVisitMutation,
    useProgNotesSearchMutation,
    useVisitChartsearchMutation,

    useGetVisitsForBracketsQuery,

    //TimeBracket hooks
    useGetBracketsOfUserByDateQuery,
    useGetAllTimeBracketsQuery,
    useGetTimeBracketByIdQuery,
    useGetTimeBracketByUserIdQuery,
    useGetUserClockInStatusQuery,
    useAddNewTimeBracketMutation,
    useUpdateTimeBracketMutation,
    useDeleteTimeBracketMutation,
    useBracketChartsearchMutation,

    //timeentry hooks
    useGetTimeEntryByIdQuery,
    useGetTimeEntryByUserIdQuery,
    useAddNewTimeEntryMutation,
    useStartUserClockMutation,
    useEditTimeEntryMutation,
    useStopUserClockMutation,
    useDeleteTimeEntryMutation,

    //miles hooks
    useGetMilesByBracketQuery,
    useAddNewMilesMutation,
    useEditMilesMutation,
    useDeleteMilesMutation,

    //timecard hooks
    useGetAllTimecardsQuery,
    useGetSingleTimecardQuery,
    useGetTimecardsByUserQuery,
    useGetTimecardsBySearchMutation,
    useUserTimecardSummaryMutation,
    useUserTimecardDataQuery,
    useSubmitTimecardMutation,
    useApproveTimecardMutation,
    useDeleteTimecardMutation,

    //admin reports hooks
    useGetUserAttendanceQuery,
    useLazyGetUserAttendanceQuery,
    // useGetUserGoalsReportQuery,

    //client reports hooks
    useGetAllClientReportsQuery,
    // useLazyGetClientReportQuery,
    useGetClientReportQuery,
    useGetReportsToApproveQuery,
    useGetClientReportByDateQuery,
    useAddClientReportMutation,
    useAddStepperReportMutation,
    useEditClientReportMutation,
    useUpdateReportStatusMutation,
    useDeleteClientReportMutation,

    //goals hooks
    useLazyGetClientReportGoalsQuery,
    useGetSingleGoalQuery,
    useAddGoalMutation,
    useEditGoalMutation,
    useDeleteGoalMutation,

    //client reports notes hooks
    useGetClientReportNotesQuery,
    useEditNotesMutation,
    useDeleteNotesMutation,

    //uploads hooks
    useGetUploadsByTypeQuery,
    useGetUploadByIdQuery,
    useGetAgencyConsentsQuery,
    useAddUploadMutation,
    // useAddConsentFormMutation,
    useEditUploadMutation,
    useSignUploadMutation,
    useDeleteUploadMutation,

    //settings hooks
    useGetAgencySettingsQuery,
    useGetAgencyStatsQuery,
    usePushDocToClientsMutation,
    useAddSettingsMutation,
    useEditSettingsMutation,

    //audit log hooks
    useGetAllAuditsQuery,
    useGetAuditsBySearchMutation,
    useUndoDeleteMutation

} = apiSlice