import { createSlice } from '@reduxjs/toolkit'

export const clientSlice = createSlice({
    name: 'client',
    initialState: {
        client: {
            currentPlan: '',
            fullName: '',
            _id: '',
            planList: []
        },
        allowStep: false
    },
    reducers: {
        setCurrentClient(state, action) {
            state.client = action.payload
        },
        setCurrentPlan(state, action) {
            state.client.currentPlan = action.payload
        },
        setNewPlanId(state, action) {
            state.client.newPlanId = action.payload
        },
        setLastPlanId(state, action) {
            state.client.lastPlanId = action.payload
        },
        setStepperGoals(state, action) {
            state.client.stepGoals= [...action.payload]
        },
        setPlansForServicePlanTab(state, action) {
            state.client.planList = action.payload
        }
    //    setAllowStep(state, action) {
    //     state.allowStep = action.payload
    //    }
    }
    
})

export const { setCurrentClient, setCurrentPlan, setNewPlanId, setLastPlanId, setStepperGoals, setPlansForServicePlanTab  } = clientSlice.actions

export default clientSlice.reducer

