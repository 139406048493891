import React from 'react';
import { Paper, Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import EditTimeEntry from './EditTimeEntry';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteTimeEntryMutation } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import './Timecard.css'



const TimeEntry = ({ entry, bracket }) => {

    const entryStart = format(parseISO(entry.timeIn), 'MM/dd/yyyy') //new Date(entry.timeIn).toLocaleTimeString("en-US"); 
        
    const entryEnd = format(parseISO(entry.timeOut), 'MM/dd/yyyy') //new Date(entry.timeOut).toLocaleTimeString("en-US");

    const bracketId = bracket._id
    const entryId = entry._id
    const [deleteEntry] = useDeleteTimeEntryMutation();
    const handleDelete = () => {
        deleteEntry({bracketId, entryId})
        toast.success("Entry Deleted", {
            position: toast.POSITION.TOP_RIGHT
        })
    }

    return (
        <>
            <Paper>
                <Box>
                        <div >
                            <h3>Time In: {entryStart}</h3>
                            <h3>Time Out: {entryEnd}</h3>
                            <h3>Type: {entry?.type}</h3>
                            <h3>Hours: {entry?.hours}</h3>
                        </div>
                        <span className='icons'>
                            <EditTimeEntry entry={entry} bracket={bracket}/>
                            <IconButton onClick={() => handleDelete()}>
                                <DeleteIcon color='error' />
                            </IconButton>
                        </span>
                </Box>
            </Paper>
        </>
    )


}

TimeEntry.propTypes = {
    entry: PropTypes.any,
    bracket: PropTypes.any
}

export default TimeEntry;