import React, { useEffect, useState } from "react";
import { Switch, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

function GoalInput({ goal, index, setValue, control, errors }) {
  
  useEffect(() => {
    if (goal.marked === true) {
      setToggle(true);
    } else {
      setToggle(false)
    }
  }, []);

  const [toggle, setToggle] = useState(false);





  return (
    <React.Fragment key={index}>
      <Controller
        control={control}
        type="checkbox"
        name={`goals.${index}.marked`}
        defaultValue={goal?.marked}
        render={({ field }) => (
          <Switch
            checked={toggle}
            onChange={(e) => {
              field.onChange(e)
              setToggle(!toggle);
              setValue(`goals.${index}.title`, goal.title)
            }}
            value={field.value}
          />
        )}
      />
      <label>{goal?.title}</label>
      <br />
      {toggle ? (
        <>
          <Controller
            control={control}
            name={`goals.${index}.note`}
            id={`goals.${index}.note`}
            defaultValue={goal?.note}
            render={({ field }) => (
              <TextField
                index={index}
                error={!!errors.note}
                value={field.value}
                onChange={(e) => field.onChange(e)}
                label="Progress Note"
                multiline
                fullWidth
              />
            )}
          />
          <br />
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

GoalInput.propTypes = {
  goal: PropTypes.any,
  index: PropTypes.any,
  register: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.any,
  visitGoals: PropTypes.array,
  setValue: PropTypes.any,
  unregister: PropTypes.any,
};

export default GoalInput;
