import React, { useEffect, useState } from 'react';
import {Box, Button, FormControl, Select, InputLabel, MenuItem, Paper, IconButton, Typography, Card, CardHeader, CardContent, Stack} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// import {useParams} from 'react-router-dom'
import { useGetClientReportQuery, useDeleteGoalMutation, useDeleteNotesMutation } from '../../../redux/apiSlice';
import AddGoal from './AddGoal';
import { useSelector } from 'react-redux';
import EditGoal from './EditGoal';
import { toast } from 'react-toastify';
import './ServicePlan.css'
import EditISPNotes from './EditISPNotes';


const ServicePlan = () => {

    // const {clientId} = useParams()
    
    const currentPlan = useSelector(state => state.client.client.currentPlan)
    const list = useSelector(state => state.client.client.planList)

    const [skip, setSkip] = useState(true)

    

    const [planId, setPlanId] = useState(currentPlan ? currentPlan : '')
    
    // const {data: list} = useGetAllClientReportsQuery(clientId)
    
    const {data: plan} = useGetClientReportQuery(planId, {skip})
    const [deleteGoal] = useDeleteGoalMutation();
    const [deleteNotes] = useDeleteNotesMutation();

    useEffect(() => {
        if (planId) {
            setSkip(false)
        }
    }, [planId])
    

    const handleGoalDelete = (goal, planId) => {
        const goalId = goal._id
        deleteGoal({planId, goalId})
        toast.success("Goal Deleted", {
            position: toast.POSITION.TOP_RIGHT
        })
    }

    const handleNotesDelete = (planId) => {
        
        deleteNotes({planId})
        toast.success("Notes Reset", {
            position: toast.POSITION.TOP_RIGHT
        })
    }

    const generateListOptions = () => {
        if (list) {

            const formatDate = (i) => {
                const date = new Date(i);
                let options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                };
                return date.toLocaleDateString("en-US", options);
              };
       
        
        // let reportsList = list.map((plan, idx) => (
        //     {
        //         planId: plan._id,
        //         type: plan.type,
        //         reportDate: plan.reportDate,
        //         startDate: plan.startDate,
        //         endDate: plan.endDate,
        //         planNumber: (idx+1)
        //      }))
             
        return list.map((plan) => {
    
            return (
               <MenuItem key={plan.planId} value={plan.planId}>
                  {plan.planNumber} {plan.type}, Report Date: {formatDate(plan.reportDate)}, Date Range: {formatDate(plan.startDate)} to {formatDate(plan.endDate)}
               </MenuItem>
            )
            })
        }
      }

      const handlePlanChange = (event) => {
        setPlanId(event.target.value);
    }
    

      return (
        <>
            <Paper sx={{ backgroundColor: '#f5f5f5'}}>
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                    }}
            >
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="isp-select-label">Reports:</InputLabel>
                        <Select
                            style={{ backgroundColor: 'white' }}
                            labelId="isp-select-label"
                            label="Reports"
                            name='reports'
                            value={planId}
                            onChange={handlePlanChange}
                        >
                            {generateListOptions()}
                        </Select>
                </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                        }}
                >
                    <Stack sx={{ p: 1 }} spacing={2}>
                        <Typography variant='h5'>Goals:</Typography>
                        <br />
                        <AddGoal planId={planId}/>
                    </Stack>
                   
                   <span display='flex' style={{flexFlow:'row wrap'}}>
                        {plan && plan?.goals?.map((goal) => (
                            <Card key={goal._id} sx={{ m: 1, p: 1, display: "inline-flex", flexDirection: 'column', border: 1, fontSize: 12 }}>
                                <CardHeader 
                                    title={goal.title}
                                    action={
                                        <>
                                            <EditGoal planId={planId} goal={goal}/>
                                            <IconButton onClick={() => handleGoalDelete(goal, planId)}>
                                                <DeleteIcon color='error' />
                                            </IconButton>
                                        </>
                                    }
                                >
                                </CardHeader>
                                <CardContent item xs={8} className='items'>
                                    <Box sx={{ p: 2 }}>
                                        <span className='info'>
                                            <Typography variant='h6'>Times/Month: {goal.frequency}</Typography>
                                            <Typography variant='h6'>Projected Hours: {goal.projectedHours}</Typography>
                                        </span>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                   </span>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                        }}
                >
                    <Stack sx={{ p: 1 }} spacing={2}>
                        <Typography variant='h5'>Notes:</Typography>
                        <br />
                        <EditISPNotes planId={planId} />
                        <br />
                        <Button size='small' color='error' onClick={handleNotesDelete}>
                            Reset Notes?
                        </Button>
                    </Stack>
                    <Card sx={{ m: 1, p: 1, display: "inline-flex", flexDirection: 'column', border: 1, fontSize: 12 }}>
                        <Typography variant='h6' style={{fontWeight: 600}}>Payee: </Typography><Typography variant='h6'>{plan?.notes?.payee}</Typography><br />
                        <Typography variant='h6'style={{fontWeight: 600}}>Vocation/Education/Volunteer Info: </Typography><Typography variant='h6'>{plan?.notes?.vocation}</Typography><br />
                        <Typography variant='h6'style={{fontWeight: 600}}>Family Input: </Typography><Typography variant='h6'>{plan?.notes?.family}</Typography><br />
                        <Typography variant='h6'style={{fontWeight: 600}}>Current Medical Status: </Typography><Typography variant='h6'>{plan?.notes?.medical}</Typography><br />
                        <Typography variant='h6'style={{fontWeight: 600}}>Long Range Goal: </Typography><Typography variant='h6'>{plan?.notes?.longGoal}</Typography>
                    </Card>
                </Box>
            </Paper>
        </>

      )

}


export default ServicePlan;
