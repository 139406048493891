import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { useGetClientsQuery } from '../../redux/apiSlice';
import { useAuth } from '../../utilities/useAuth';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


const DataTable = () => {
  const {role, id} = useAuth();
  const userId = id;
  console.log("clients: ", role, id)

  
  const [showHidden, setShowHidden] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([])

const {
  data,
  isLoading,
  isSuccess,
  
} = useGetClientsQuery({role, userId})

useEffect(() => {
  if (isSuccess) {
    let filteredRows = data;
    
    if (!showHidden) {
      filteredRows = filteredRows.filter(c => c.enrollment.enrolled);
    }

    if (searchText) {
      filteredRows = filteredRows.filter(c =>
        c.fullName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setRows(filteredRows);
  }
}, [data, isSuccess, showHidden, searchText]);




const columns = 

   [
  {
    field: '_id', 
    headerName: 'Client Chart', 
    width: 80,
    renderCell: (params) => (
      <Link to={`/ClientChart/${params.value}`}><AccountCircleIcon /></Link>
    )
  },
  {field: 'lastName', headerName: 'Last Name', width: 150},
    
  
  {field: 'firstName', headerName: 'First Name', width: 150},
  { field: 'phone', 
    headerName: 'Phone', 
    width: 150,
    valueGetter: (params) => params.row.personalInfo.phone
  },
  { field: 'enrolled',
    headerName: 'Status',
    width: 100,
    renderCell: (params) => {
      const isEnrolled = params.row.enrollment.enrolled;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isEnrolled ? (
            <CheckBoxIcon color="success" />
          ) : (
            <CheckBoxOutlineBlankIcon color="error" />
          )}
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            {isEnrolled ? 'Active' : 'Inactive'}
          </Typography>
        </div>
      );
    }
  }
   ];


let content

if (isLoading) {
  content = <CircularProgress />
} else if (isSuccess) {
  content = 
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
  
     <Box style={{ marginBottom: '10px' }}>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      <Button onClick={() => setShowHidden(!showHidden)}>
        {showHidden ? 'Hide Inactive' : 'Show Inactive'}
      </Button>
    </Box> <br />
    <Divider />
    <Box sx={{ flexGrow: 1 }}>
    <DataGrid 
      autoHeight
      autoWidth
      getRowId={(row) => row._id}
      rows={rows}
      columns={columns}
      //autoPageSize
      sx={{ height: '100%', width: '100%' }}
      rowsPerPageOptions={[20, 50, 100]}      
 />
 </Box>
 
 </Box>

}


  return (
    <div>
      <h1>All Current Clients</h1>
      
      <div>
        {content}
      </div>
      
      
    </div>
  )
}

export default DataTable;