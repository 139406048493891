import React, { useEffect, useState } from 'react';
import {Button, TextField, Dialog, DialogTitle,
        DialogActions, DialogContent, DialogContentText, Typography, Select, MenuItem, InputLabel, Divider, TableCell, Table, TableRow, TableBody} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useEditVisitMutation, useGetLogsForVisitQuery } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import GoalInput from './GoalInput';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenState } from '../../redux/slices/visitSlice';
import { useAuth } from '../../utilities/useAuth';
import SignVisit from './SignVisit';
import AddBldPrsrFromVisit from '../clientviews/logviews/AddBldPrsrFromVisit';
import AddEntryFromVisit from '../clientviews/logviews/AddEntryFromVisit';

const statusOptions = [
  { label: 'Scheduled', value: 'Scheduled'},
  { label: 'On-Site', value: 'On-Site'},
  { label: 'Completed', value: 'Completed'},
  { label: 'Canceled', value: 'Canceled'},
]

//pass trigger for incomplete on backend. if dirtyfields.length? or on backend, 

const EditVisit = ({ visitId, clientName, clientId }) => { 
  const {id} = useAuth();
  const isUser = id;

  const hasVisit = useSelector(state => state.visit?.visit)
  // console.log("hasVist: ", hasVisit)

  const [hasLogs, setHasLogs] = useState([]);
  const [doneLogs, setDoneLogs] = useState([]);
  const [logDate, setLogDate] = useState(hasVisit.visitStart)
  const [skip, setSkip] = useState(true)

  useEffect(() => {
    if(clientId){
      setSkip(false)
    }
  }, [clientId])
  // console.log("edit visitId: ", visitId)

  const [editVisit] = useEditVisitMutation();
  const {data, isSuccess} = useGetLogsForVisitQuery(clientId, {skip})
  // console.log("clientId: ", clientId)
  // console.log("isSuccess & data", isSuccess, data, data.length)
  console.log("hasLogs: ", hasLogs)

  useEffect(() => {
    if(isSuccess && data) {
      setHasLogs(data)
    } else {
      setHasLogs([])
    }
  }, [isSuccess, data])

  

  const [open, setOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(hasVisit.status);

  const dispatch = useDispatch()


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(setOpenState(false))
    setOpen(false);
    reset();
  };
  
  const goals = hasVisit?.goals

  const { register, handleSubmit, control, setValue, reset, watch, getValues, formState: { errors, dirtyFields } } = useForm({
    defaultValues: {
    client: hasVisit.client,
    visitStart: hasVisit.visitStart,
    visitEnd: hasVisit.visitEnd,
    location: hasVisit.location,
    goals: hasVisit.goals,
    status: hasVisit.status,
    cancelNote: hasVisit.cancelNote,
    clientSig: hasVisit.clientSig,
    sigToken: hasVisit.sigToken
    },
    shouldUnregister: true
  });
 
 const onSubmit= async (data) => {
    
  console.log("triggered?")
    // console.log("visit data: ", data)
    data.client = hasVisit.client
    const _data = data.goals?.filter(goal => goal.marked != false)
    data.goals = _data
    // console.log("post data: ", data)

                 
  try {
    // const payload =  
    await editVisit({ visitId, data, isUser }).unwrap();
      // console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save visit: ', err)
    }

    toast.success("Visit Updated", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
 }

 const onError = (errors) => {
  toast.error("Error Updating Visit", {
    position: toast.POSITION.TOP_CENTER
  });
   console.log('error in visit form: ', errors);
 }

 const generateOptions = () => {
  return statusOptions.map((option) => {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  });
};

const watchStatus = watch('status')

useEffect(() => {
  if (dirtyFields.status) {
    // console.log(watchStatus)
    if (watchStatus === 'Completed') {
      setNewStatus('Completed')
    } else if (watchStatus === 'Canceled') {
      setNewStatus('Canceled')
    }
  }
}, [dirtyFields, watchStatus])


useEffect(() => {
  if(hasVisit) {
    hasVisit.goals.forEach((g, idx) => {
      // console.log(g)
      if (g.marked) {
        setValue(`goals.${idx}.title`, g.title)
      }
    })
  }
}, [hasVisit])

const handleLogsToVisit = (logData) => {
  setDoneLogs(prev => [...prev, logData])
}

const handleDateChange = (e) => {
  console.log("e.target.value: ", e.target.value)
  setLogDate(e.target.value)
}

console.log("doneLogs: ", doneLogs)

const renderLogRows = () => {
  return doneLogs?.map((l, idx) => {
    const commonFields = (
      <React.Fragment key={idx}>
        <TableCell>Type: {l.type}</TableCell>
        <TableCell>{new Date(l.time).toLocaleTimeString()}</TableCell>
      </React.Fragment>
    );
  
    if (l.type === 'Blood Pressure'){
      return (
        <React.Fragment key={idx}>
          <TableRow>
          {commonFields}
          <TableCell>Pressure: {l.fields.systolic}/{l.fields.diastolic}</TableCell>
          <TableCell>Pulse: {l.fields.pulse}</TableCell>
          <TableCell>Note: {l.fields.note}</TableCell>
          </TableRow>
        </React.Fragment>
      )
    }
    else {
      const dynamicFields = l.data
  ? Object.keys(l.data)
      .filter((key) => !key.toLowerCase().includes('date'))
      .map((key) => (
        l.data[key] !== undefined ? (
          <TableCell key={key}>{`${key}: ${l.data[key]}`}</TableCell>
        ) : null
      ))
  : [];

  
      return (
        <React.Fragment key={idx}>
          <TableRow>
          {commonFields}
          {dynamicFields}
          </TableRow>
        </React.Fragment>
      );
    }
  })
}

// const logSet = 

  return (
    <div>
      <Button color='primary' variant='contained' onClick={handleClickOpen}>
        Edit Visit
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Visit</DialogTitle>
        <DialogContent>

        <DialogContentText>
            {clientName ? (`Visit For: ${clientName}`) : ('') }
        </DialogContentText>
    
        
          <form id="editVisit" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
        
     <br></br>
     <section>
      <Controller
        control={control}
        name="visitStart"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
         
          label="Visit Start"
          error={!!errors.visitStart}
          value={field.value}
          onChange={(e)=> {field.onChange(e), handleDateChange(e)}}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.visitStart && "Select Time In"}
      </section>
      <br></br>
      <section>
      <Controller
        control={control}
        name="visitEnd"
        
  
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
             
              label="Visit End"
              error={!!errors.visitEnd}
              value={field.value}
              onChange={(e)=>field.onChange(e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          )}
          />
      <br></br>
      {errors.visitEnd && "Select Time Out"}
      </section>
      <br></br>
      <section>
        <TextField
          {...register('location', { required: true})}
          error={!!errors.location}
          label="Location"
          name='location'
          id='location'
          required
        />
      <br></br>
        {errors.location && "List a location for the visit"}
      </section>
      <br />
      <section>
        <InputLabel id="status-label">Status</InputLabel>
        <Controller
          name="status"
          control={control}
          labelId="status-label"
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={field.onChange}
              label="Status"
            >
              {generateOptions()}
            </Select>
          )}
        />
        <br />
        {newStatus === 'Canceled' && 
          <TextField
            name='cancelNote'
            {...register('cancelNote')}
            label='Reason for Cancellation'
          />
        }
      </section>
      {goals?.length >= 1 ? ( goals.map((goal, index) => (
          <GoalInput 
            key={index}
            goal={goal} 
            index={index} 
            control={control}
            errors={errors}
            setValue={setValue}
          />
      ))
      ):(
        <Typography>No service plan with goals for this date. Goals must be set in the plan to reflect here.</Typography>
      )
    }
    <br />
    {hasLogs?.length > 0 && hasLogs.map((log, idx) => {
        if(log.type === 'Blood Pressure'){
          return <AddBldPrsrFromVisit key={idx} log={log} clientId={clientId} isUser={isUser} hasDate={logDate} addLogSet={handleLogsToVisit} />
        } else {
          return <AddEntryFromVisit key={idx} log={log} clientId={clientId} isUser={isUser} hasDate={logDate} addLogSet={handleLogsToVisit} />
        }
    })}
    {doneLogs.length > 0 && 
      <div>
        <Typography>Logs added during this visit:</Typography>
         <Table>
            <TableBody>
                {renderLogRows()}
            </TableBody>
         </Table>
      </div>  
    }
     <br />
     {hasVisit.clientSig && hasVisit.status === 'Completed' ? <img src={hasVisit.clientSig} style={{wdith: '200px', height: '200px'}} /> : "" }
        </form>
        <Divider sx={{m: 1, p: 1}} />
        </DialogContent>
        <DialogActions>
          <Button color='warning' variant='contained' onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" form="editVisit" type="submit">
            Save
          </Button>
          {newStatus === 'Completed' && hasVisit.sigToken == null ? (
            <>
            <br />
            <SignVisit control={control} visit={getValues()} visitId={visitId} isUser={isUser} name={clientName} />
            </> ) : ('')
          }
        </DialogActions>
        </Dialog>
        </div>
    )}

EditVisit.propTypes = {
  visitId: PropTypes.any,
  clientName: PropTypes.any,
  clientId: PropTypes.any
}

export default EditVisit;
